<template>
    <div class="js-entry o-entry c-entity-entry">
        <component :is="entityComponent" :entityData="entryData.Entity"></component>
        <div class="c-entity-entry__more-link" v-if="!isOwnPage">
            <router-link :to="'/page/' + entryData.Json.EntityPageID">
                {{ $t("global.more") }}
            </router-link>
        </div>
    </div>
</template>

<script>
import entryMixin from '../../mixins/entry'
import NewsEntity from './EntityEntry/NewsEntity.vue'
import EventEntity from './EntityEntry/EventEntity.vue'

export default {
  mixins: [entryMixin],
  computed: {
    entityComponent () {
      switch (this.entryData.Entity.Class) {
        case 'News':
          return 'news-entity'
        case 'Event':
          return 'event-entity'
        default:
          throw new Error(`entity class "${this.entryData.Entity.Class}" is not supported`)
      }
    },
    isOwnPage () {
      return this.entryData.Json.EntityPageID === 0
    }
  },
  components: {
    NewsEntity,
    EventEntity
  }
}
</script>
