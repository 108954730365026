<template>
    <div class="c-event-entity">
        <div class="c-event-entity__image">
            <img v-if="entityData.Image.BinaryID" :src="imageUrl" :title="entityData.Image.Alt" />
            <div class="c-event-entity__image-caption" v-if="entityData.Image.Caption.length > 0">
                {{ entityData.Image.Caption }}
            </div>
        </div>
        <div class="c-event-entity_meta">
            <div class="c-event-entity_date">
                <i class="xp xp-calendar"></i>
                <span>{{ entityData.StartDate | dateString }}</span> - <span>{{ entityData.EndDate | dateString }}</span>
            </div>
            <div v-if="entityData.ShowTime">
                <i class="xp xp-clock-o"></i>
                <span>{{ entityData.StartDate | timeString }}</span> - <span>{{ entityData.EndDate | timeString }}</span>
            </div>
            <div v-if="entityData.Location.length > 0">
                <i class="xp xp-map-marker"></i>
                <span>{{ entityData.Location }}</span>
            </div>
        </div>
        <div class="c-event-entity__teaser" v-html="entityData.Teaser"></div>
    </div>
</template>

<script>
export default {
  props: ['entityData'],
  computed: {
    imageUrl () {
      return this.$xp.content.getImageUrl(this.entityData.Image)
    }
  }
}
</script>
